<template>
  <div class="new-pay-container">
    <pay v-bind:pay="message" ref="pay"></pay>
  </div>
</template>

<script>
import pay from '../../components/pay'

export default {
  name: "showPayPage",

  components: {
    'pay': pay
  },
  data() {
    return {
      message: "",
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.orderId = this.$route.query.orderId;//获取加油付款页面生成的orderid
      const res = await this.$get("/jsapi/pay_vip", {
        orderId: this.orderId,
      })
      if (res.code === 200) {
        const data = res.data;
        this.message = data.message;
        this.$refs.pay.getMsg(res.data.message);
      }
    },
  },
}
</script>

<style scoped>

</style>